import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  compact,
  defaultTo,
  find,
  findIndex,
  flatten,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  trim,
  toInteger,
  toNumber,
  forEach
} from "lodash";
import jsonLogic from "json-logic-js";

import { gql } from "apollo-boost";
import { ThemeProvider } from "react-jss";
import { useUrlQuery } from "hooks/useUrlQuery";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { RenderFormContainer } from "components/FormComponents/RenderFormContainer";
import { FormSuccessPageSection } from "components/FormComponents/FormSuccessPageSection";
import {
  calculateCost,
  formHasCost,
  getLimitSubscriptionPerFeatureType,
  getSubscriptionStatusName,
  stringToBoolean
} from "utils/helperFunctions";
import { INITIALIZE_QUESTION_BEING_RENDERED } from "reducers/form-actions";
import { FormProvider } from "./FormProvider";
import { useInterval } from "hooks/useInterval";
import {
  EQUALS,
  FEATURE_MAX_LEAD_VALUE,
  FEATURE_NUMBER_OF_LEADS,
  FEATURE_WHITE_LABEL,
  IS_EMPTY,
  NOT_EMPTY,
  NOT_EQUAL,
  OPERATORS_SYMBOL,
  FEATURE_CONNECTORS
} from "utils/constants";
import { getJsonRuleLogic } from "components/FormComponents/RenderFormColumn";

import "./render.scss";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import NotificationAlert from "components/NotificationAlert";

const notificationOptions = (notificationText, type, icon, notificationTitle) => ({
  place: "tc",
  message: (
    <div className="alert-text ml-2">
      {notificationTitle && (
        <>
          <span className="alert-title font-weight-bold" data-notify="title">
            {notificationTitle}
          </span>
          <br />
        </>
      )}
      <span data-notify="title">{notificationText}</span>
    </div>
  ),
  type: type,
  icon: icon,
  autoDismiss: 5
});

const GET_FORM = gql`
  query GetForm($workspaceId: ID!, $formId: ID!, $startRange: String, $endRange: String) {
    workspaceFromHash(id: $workspaceId) {
      id
      company {
        id
        planId
        users {
          items {
            id
            email
          }
        }
        subscriptions {
          id
          status
          cancelAt
          items {
            id
            plan {
              id
              product
            }
          }
        }
      }
      leadsByDateRange(startRange: $startRange, endRange: $endRange) {
        count
      }
      formFromHash(id: $formId) {
        id
        name
        description
        display
        schema
        formVersion
        formType
        lastUpdate
        publishTheme {
          id
          type
          styleSchema
        }
      }
    }
    plans {
      id
      name
      description
      stripeId
      features {
        id
        name
        value
      }
    }
  }
`;

const UPDATE_LEAD = gql`
  mutation UpdateLead($leadId: ID, $leadData: LeadData, $autoSave: Boolean, $finished: Boolean, $hasConnectors: Boolean) {
    updateLead(leadId: $leadId, leadData: $leadData, autoSave: $autoSave, finished: $finished, hasConnectors: $hasConnectors) {
      id
    }
  }
`;

const SEND_EMAIL = gql`
  mutation SendLeadEmails(
    $email: String!
    $cost: JSONObject!
    $quote: [JSONObject!]
    $workspaceId: ID!
    $formId: ID!
    $action: String
    $whiteLabel: Boolean
    $emailFollowup: String
    $isConfirmationEmail: Boolean
  ) {
    sendLeadEmails(
      email: $email
      cost: $cost
      quote: $quote
      workspaceId: $workspaceId
      formId: $formId
      action: $action
      whiteLabel: $whiteLabel
      emailFollowup: $emailFollowup
      isConfirmationEmail: $isConfirmationEmail
    )
  }
`;

const UPDATE_FORM_COUNTER = gql`
  mutation UpdateFormCounter($formId: ID) {
    updateFormCounter(formId: $formId) {
      id
    }
  }
`;

export const getFormJSONValue = (json, key, subKey) => {
  const value = json.find((val) => val.id === key);
  return value
    ? value.type !== "address"
      ? get(value, ["answer", "value"], "")
      : get(
          find(get(value, ["answer"], []), (answer) => answer.id === `${subKey}_${key}`),
          "value",
          ""
        )
    : "";
};

export const checkErrorsMSG = (notificationAlert) => {
  for (let idx = 0; idx <= get(notificationAlert, ["current", "state", "notifyBC"]).length - 1; idx++) {
    if (get(notificationAlert, ["current", "state", "notifyBC", idx, "props", "isOpen"]) === true) return false;
  }
  return true;
};

function FormBuilder() {
  const query = useUrlQuery();
  const dispatch = useDispatch();
  const leadIdRef = useRef();
  const [saved, setSaved] = useState(false);
  const [autoSaved, setAutoSaved] = useState(false);
  const [formCost, setFormCost] = useState();
  const currentWorkspaceId = query.get("wid");
  const formId = query.get("id");
  const notificationAlert = useRef(null);

  localStorage.removeItem("state");

  const {
    data: formData,
    loading: formLoading,
    refetch
  } = useQuery(GET_FORM, {
    variables: {
      formId: formId,
      workspaceId: currentWorkspaceId,
      startRange: moment([moment().year(), moment().month(), 1]).format("YYYY-MM-DD"),
      endRange: moment([moment().year(), moment().month(), moment().daysInMonth()]).format("YYYY-MM-DD")
    },
    skip: !formId,
    fetchPolicy: "no-cache"
  });

  const [updateFormCounter] = useMutation(UPDATE_FORM_COUNTER);

  useEffect(() => {
    if (!formLoading && formData) {
      updateFormCounter({
        variables: {
          formId: formId
        }
      });
    }
  }, [formData, formId, formLoading, updateFormCounter]);

  const companyId = get(formData, ["workspaceFromHash", "company", "id"], {});
  const form = get(formData, ["workspaceFromHash", "formFromHash"], {});
  const formVersion = get(form, "formVersion");
  const formType = get(form, "formType");
  const workspaceId = get(formData, ["workspaceFromHash", "id"]);
  const planId = get(formData, ["workspaceFromHash", "company", "planId"]);
  const totalLeads = get(formData, ["workspaceFromHash", "leadsByDateRange", "count"]);

  const compSubscription =
    defaultTo(get(formData, ["workspaceFromHash", "company", "subscriptions"]), []).length > 0
      ? get(formData, ["workspaceFromHash", "company", "subscriptions"])[0]
      : null;
  let compProduct =
    compSubscription && compSubscription.status !== "canceled"
      ? get(formData, "plans", []).find((pr) => pr.stripeId === compSubscription.items[0].plan.product)
      : null;
  const subStatus = getSubscriptionStatusName(compSubscription);
  const subEndDate = compSubscription && compSubscription.cancelAt ? new Date(compSubscription.cancelAt * 1000) : null;

  if (!compProduct) {
    compProduct = get(formData, "plans", []).find((pr) => pr.id === planId);
  }
  const limitLeads = toInteger(getLimitSubscriptionPerFeatureType({ compProduct }, FEATURE_NUMBER_OF_LEADS));
  const maxLeadValue = toInteger(getLimitSubscriptionPerFeatureType({ compProduct }, FEATURE_MAX_LEAD_VALUE));
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType({ compProduct }, FEATURE_WHITE_LABEL));
  const hasConnectors = stringToBoolean(getLimitSubscriptionPerFeatureType({ compProduct }, FEATURE_CONNECTORS));
  

  const reachLeads = limitLeads === -1 ? false : totalLeads >= limitLeads;
  const reachLeadValue = (cost) => (maxLeadValue === -1 ? false : cost > toNumber(maxLeadValue));

  const subProd = get(compProduct, "stripeId");
  const isCanceledOrExpired = isEmpty(subProd)
    ? false
    : (subStatus != null && subStatus !== "Active" && subStatus !== "Trial") || moment(subEndDate).isAfter();

  const { quoteJSON } = useSelector((state) => state.render);
  const [allFields, setAllFields] = useState([]);
  const [leadData, setLeadData] = useState();

  const validateForm = (sectionId) => {
    const jsonRuleLogic = new Map();
    const applyRuleSet = new Map();
    getJsonRuleLogic(allFields, quoteJSON, jsonRuleLogic, applyRuleSet, formError, setFormError);

    const sections = compact(
      map(get(formData, ["workspaceFromHash", "formFromHash", "schema", "sections"], []), (section) =>
        isNotEmptySection(section) ? section : null
      )
    );

    const sectionJsonRuleLogic = new Map();
    const sectionApplyRuleSet = new Map();
    getJsonRuleLogic(sections, quoteJSON, sectionJsonRuleLogic, sectionApplyRuleSet, formError, setFormError);

    const sectionsWithRules = compact(
      map(sections || [], (section) => {
        const findRule = sectionApplyRuleSet.get(section.id);
        const rule =
          findRule &&
          ((findRule.rule && findRule.action === "hidden") || (!findRule.rule && findRule.action === "show"));
        return !rule ? section : null;
      })
    );

    return compact(
      map(allFields, (field) => {
        if (field.required && (isNil(sectionId) ? true : field.section === sectionId)) {
          if (
            !get(
              find(sections, (section) => section.id === field.section),
              ["dynamicRules", "active"],
              false
            )
          ) {
            if (!applyRuleSet.get(field.id)) {
              if (
                findIndex(
                  quoteJSON,
                  (quote) => quote.id === field.id && trim(get(quote, ["answer", "value"], "")) !== ""
                ) === -1
              ) {
                return field.title;
              } else {
                return undefined;
              }
            } else {
              if (
                (applyRuleSet.get(field.id).rule && applyRuleSet.get(field.id).action === "hidden") ||
                (!applyRuleSet.get(field.id).rule && applyRuleSet.get(field.id).action === "show")
              ) {
                return undefined;
              } else {
                if (
                  findIndex(
                    quoteJSON,
                    (quote) => quote.id === field.id && trim(get(quote, ["answer", "value"], "")) !== ""
                  ) === -1
                ) {
                  return field.title;
                } else {
                  return undefined;
                }
              }
            }
          } else {
            if (find(sectionsWithRules, (rule) => rule.id === field.section)) {
              if (!applyRuleSet.get(field.id)) {
                if (
                  findIndex(
                    quoteJSON,
                    (quote) => quote.id === field.id && trim(get(quote, ["answer", "value"], "")) !== ""
                  ) === -1
                ) {
                  return field.title;
                } else {
                  return undefined;
                }
              } else {
                if (
                  (applyRuleSet.get(field.id).rule && applyRuleSet.get(field.id).action === "hidden") ||
                  (!applyRuleSet.get(field.id).rule && applyRuleSet.get(field.id).action === "show")
                ) {
                  return undefined;
                } else {
                  if (
                    findIndex(
                      quoteJSON,
                      (quote) => quote.id === field.id && trim(get(quote, ["answer", "value"], "")) !== ""
                    ) === -1
                  ) {
                    return field.title;
                  } else {
                    return undefined;
                  }
                }
              }
            }
          }
        } else {
          return undefined;
        }
      })
    );
  };

  const [updateLead, { data: updatedData, loading: updating }] = useMutation(UPDATE_LEAD, {
    onCompleted: () => setSaved(true)
  });

  useEffect(() => {
    if (updatedData && !updating) {
      leadIdRef.current = get(updatedData, ["updateLead", "id"]);
    }
  }, [updatedData, updating]);

  const getLeadData = (quoteJSON) => ({
    company_id: companyId,
    first_name: getFormJSONValue(quoteJSON, "LeadDetailsSectionFirstName"),
    last_name: getFormJSONValue(quoteJSON, "LeadDetailsSectionLastName"),
    email: getFormJSONValue(quoteJSON, "LeadDetailsSectionEmail"),
    phone: getFormJSONValue(quoteJSON, "LeadDetailsSectionPhone"),
    address: getFormJSONValue(quoteJSON, "LeadDetailsSectionAddress", "address"),
    city: getFormJSONValue(quoteJSON, "LeadDetailsSectionAddress", "city"),
    state: getFormJSONValue(quoteJSON, "LeadDetailsSectionAddress", "state"),
    zipcode: getFormJSONValue(quoteJSON, "LeadDetailsSectionAddress", "zip"),
    quote_json_file: { quoteJSON }
  });

  const autoSaveFlag = () => quoteJSON.length > 0 && !(leadData && isEqual(leadData, getLeadData(quoteJSON)));

  useInterval(
    () => {
      saveLeadAction(true);
    },
    autoSaveFlag() ? 5 : null
  );

  const saveLeadAction = (autoSave = false) => {
    const hasErrors = validateForm();
    if (!autoSave && hasErrors.length > 0) {
      if (checkErrorsMSG(notificationAlert)) {
        notificationAlert.current.notificationAlert(
          notificationOptions(
            `Please verify the required fields: ${hasErrors.join(", ")}`,
            "danger",
            "fas fa-exclamation-triangle"
          )
        );
      }
    } else {
      if (quoteJSON.length > 0) {
        const value = calculateCost(quoteJSON);

        setFormCost(value);
        setAutoSaved(autoSave);
        setLeadData(getLeadData(quoteJSON));
        saveLead({
          autoSave: autoSave,
          finished: false,
          leadId: leadIdRef.current,
          leadData: {
            ...getLeadData(quoteJSON),
            value: value,
            form_version_id: formVersion,
            form_id: formId,
            emailed: true,
            workspace_id: workspaceId,
            progress: autoSave ? "incomplete" : "undecided",
            hidden: reachLeadValue(formHasCost(value)) || reachLeads
          },
          hasConnectors: hasConnectors
        });
      }
    }
  };

  const updateLeadAction = (interested) => {
    saveLead({
      autoSave: false,
      finished: true,
      leadId: leadIdRef.current,
      hasConnectors: hasConnectors,
      leadData: {
        ...getLeadData(quoteJSON),
        form_version_id: formVersion,
        form_id: formId,
        progress: interested
      }
    });
  };

  const theme = get(form, ["publishTheme", "styleSchema"], {});
  const inputFontsize = get(theme, ["& .form-control", "fontSize"], "14 px");
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [emailError, setEmailError] = useState();
  const [confirmationMSG, setConfirmationMSG] = useState();
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    if (formError) {
      notificationAlert.current.notificationAlert(
        notificationOptions(
          "Please reach out to us directly if you have any questions.",
          "danger",
          "fas fa-exclamation-triangle",
          "We apologize there has been an issue."
        )
      );
    }
  }, [formError]);

  const saveLead = (lead) => {
    updateLead({
      variables: {
        autoSave: lead.autoSave,
        finished: lead.finished,
        leadId: lead.leadId,
        leadData: lead.leadData,
        hasConnectors: lead.hasConnectors
      }
    });
  };

  const startOverAction = (e) => {
    e.preventDefault();
    refetch();
    setSaved(false);
    setConfirmationMSG();
    leadIdRef.current = undefined;
    dispatch({
      type: INITIALIZE_QUESTION_BEING_RENDERED
    });
  };

  const successRuleLogic = (rules, field, text, selectedFormCost) => {
    const ruleGroups = get(rules, ["ruleGroups"], []);
    for (const ruleGroup of ruleGroups) {
      let jLogic = { and: [] };
      for (const rule of ruleGroup.rules) {
        const operator =
          rule.operator === IS_EMPTY
            ? OPERATORS_SYMBOL[EQUALS]
            : rule.operator === NOT_EMPTY
            ? OPERATORS_SYMBOL[NOT_EQUAL]
            : OPERATORS_SYMBOL[rule.operator];
        jLogic.and.push({ [operator]: [selectedFormCost, Number(rule.answer)] });
      }
      if (jsonLogic.apply(jLogic)) {
        return ruleGroup[field];
      }
    }
    return text;
  };

  const getFormCost = (costToCompare) => {
    return get(formCost, [costToCompare, "expected"], 0);
  };

  const getCustomSuccessMSG = (field, value) => {
    const successPageData = get(form, ["schema", "success", "section"], {});
    const dynamicRules = get(successPageData, "hasDynamicSuccessText", false)
      ? get(form, ["schema", "success", "section", "dynamicRules"], [])
      : false;
    const costToCompare = get(successPageData, "costToCompare", "cost");
    const text = get(successPageData, "hasCustomSuccessText", false) ? get(successPageData, field, "") : value;
    const selectedFormCost = getFormCost(costToCompare);
    return dynamicRules && selectedFormCost > 0 ? successRuleLogic(dynamicRules, field, text, selectedFormCost) : text;
  };

  const notInterestedAction = (e) => {
    e.preventDefault();
    updateLeadAction("uninterested");
    const successPageData = get(form, ["schema", "success", "section"], {});
    if (!reachLeadValue(formHasCost(formCost)) || !reachLeads) {
      sendEmail({
        variables: {
          email: getFormJSONValue(quoteJSON, "LeadDetailsSectionEmail"),
          cost: formCost,
          quote: quoteJSON,
          workspaceId: currentWorkspaceId,
          formId: formId,
          action: "Un-Interested",
          whiteLabel: hasWhiteLabel,
          emailFollowup: get(form.schema, ["options", "emailFollowup"], "active")
        }
      }).then(({ data, errors }) => {
        if (errors) setEmailError(errors[0].message);
        if (data && data.sendLeadEmails) {
          if (successPageData.hasRedirect) {
            window.location.href = redirectToPage(false);
          } else {
            setConfirmationMSG(
              getCustomSuccessMSG("notInterestedText", "<p>Thank you, We appreciate your feedback.</p>")
            );
          }
        }
      });
    } else {
      if (successPageData.hasRedirect) {
        window.location.href = redirectToPage(false);
      } else {
        setConfirmationMSG(getCustomSuccessMSG("notInterestedText", "<p>Thank you, We appreciate your feedback.</p>"));
      }
    }
  };

  const redirectToPage = (interested = true) => {
    const field = interested ? "interestedLink" : "notInterestedLink";
    const successPageData = get(form, ["schema", "success", "section"], {});
    const dynamicRules = get(successPageData, "hasDynamicSuccessText", false)
      ? get(form, ["schema", "success", "section", "dynamicRules"], [])
      : false;
    const costToCompare = get(successPageData, "costToCompare", "cost");
    const text = get(successPageData, "hasCustomSuccessText", false) ? get(successPageData, field, "") : "";
    const selectedFormCost = getFormCost(costToCompare);
    return dynamicRules && selectedFormCost > 0 ? successRuleLogic(dynamicRules, field, text, selectedFormCost) : text;
  };

  const onConfirmAction = (e) => {
    e.preventDefault();
    updateLeadAction("interested");
    const successPageData = get(form, ["schema", "success", "section"], {});
    if (!reachLeadValue(formHasCost(formCost)) || !reachLeads) {
      sendEmail({
        variables: {
          email: getFormJSONValue(quoteJSON, "LeadDetailsSectionEmail"),
          cost: formCost,
          quote: quoteJSON,
          workspaceId: currentWorkspaceId,
          formId: formId,
          action: "Interested",
          whiteLabel: hasWhiteLabel,
          emailFollowup: get(form.schema, ["options", "emailFollowup"], "active")
        }
      }).then(({ data, errors }) => {
        if (errors) setEmailError(errors[0].message);
        if (data && data.sendLeadEmails) {
          if (successPageData.hasRedirect) {
            window.location.href = redirectToPage();
          } else {
            setConfirmationMSG(
              getCustomSuccessMSG("interestedText", "<p>Thank you, We look forward to working together.</p>")
            );
          }
        }
      });
    } else {
      if (successPageData.hasRedirect) {
        window.location.href = redirectToPage();
      } else {
        setConfirmationMSG(
          getCustomSuccessMSG("interestedText", "<p>Thank you, We look forward to working together.</p>")
        );
      }
    }
  };

  useEffect(() => {
    if (emailError) {
      notificationAlert.current.notificationAlert(
        notificationOptions(emailError, "danger", "fas fa-exclamation-triangle")
      );
    }
  }, [emailError]);

  const [activeSection, setActiveSection] = useState(0);
  const [sectionsWithoutEmpty, setSectionsWithoutEmpty] = useState([]);

  const isNotEmptySection = (section) =>
    compact(flatten(map(section.rows, (row) => map(row.columns, (column) => isEmpty(column.questions))))).length > 0
      ? false
      : true;

  const applyRuleSet = new Map();
  const jsonRuleLogic = new Map();
  useEffect(() => {
    if (!formLoading && formData) {
      const sections = compact(
        map(get(formData, ["workspaceFromHash", "formFromHash", "schema", "sections"], []), (section) =>
          isNotEmptySection(section) ? section : null
        )
      );

      getJsonRuleLogic(sections, quoteJSON, jsonRuleLogic, applyRuleSet, formError, setFormError);

      const sectionsWithRules = compact(
        map(sections || [], (section) => {
          const findRule = applyRuleSet.get(section.id);
          const rule =
            findRule &&
            ((findRule.rule && findRule.action === "hidden") || (!findRule.rule && findRule.action === "show"));
          return !rule ? section : null;
        })
      );

      setSectionsWithoutEmpty(sectionsWithRules);
    }

  }, [formLoading, formData, quoteJSON]);

  useEffect(() => {
    // Clean Up the question displayed
    localStorage.removeItem("questionDisplayed");
  }, [])

  // Clean Up the Question to only display the answered questions
  if(!(autoSaved || !saved)) {
    const questionDisplayed = localStorage.getItem("questionDisplayed");
    if(questionDisplayed) {
      forEach(quoteJSON, (question, key) => {
        const arrQuestionDisplay = JSON.parse(questionDisplayed);
        const elementIndex = arrQuestionDisplay.findIndex((element) => element?.id === question?.id);
        if(elementIndex !== -1) {
          if(arrQuestionDisplay[elementIndex].flag === "hide") {
            quoteJSON.splice(key, 1);
          }
        }
      });
    }
  }


  const insertScriptTagToPage = (codeBlock, position = "body") => {
    // Get all scripts 
    const scripts = codeBlock.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; i++) {

      // Create template 
      const script = document.createElement('script');
      
      // Get and set attributes from existing <script> tags
      const attributes = Array.from(scripts[i].attributes);
      // eslint-disable-next-line no-loop-func
      attributes.forEach(function(attribute) {
        script.setAttribute(attribute.name, attribute.value);
      });

      // Add script content to new script variable 
      script.innerHTML = scripts[i].innerHTML;

      // Add to proper place on page 
      if(position === "body"){
        document.body.appendChild(script);
      } else {
        document.head.appendChild(script);
      }
      script.parentNode.removeChild(script);

    }

    return codeBlock;
  }

  useEffect(() => {
    const customCodeHeader = get(form.schema, ["options", "customCodeHeader"], "");
    if(customCodeHeader) {
      // Set the header code to a variable
      let scriptElementHeader = document.createElement('div');
      scriptElementHeader.innerHTML = customCodeHeader;

      const htmlTag = document.documentElement;
      const isCustomCodeHead = htmlTag.getAttribute("isCustomCodeHead");

      if(isCustomCodeHead === null) {
        htmlTag.setAttribute("isCustomCodeHead", true);
        // Remove the script tags from the code and parse them
        const scriptElementHeaderParsed = insertScriptTagToPage(scriptElementHeader,'head');
        document.head.insertAdjacentHTML('beforeend', scriptElementHeaderParsed.innerHTML);
      }
    }
    const customCodeFooter = get(form.schema, ["options", "customCodeFooter"], "");
    if(customCodeFooter) {
      // Set the header code to a variable
      let scriptElementFooter = document.createElement('div');
      scriptElementFooter.innerHTML = customCodeFooter;

      const htmlTag = document.documentElement;
      const isCustomCodeBody = htmlTag.getAttribute("isCustomCodeBody");

      if(isCustomCodeBody === null) {
        htmlTag.setAttribute("isCustomCodeBody", true);
        // Remove the script tags from the code and parse them 
        const scriptElementFooterParsed = insertScriptTagToPage(scriptElementFooter,'body');
        document.body.insertAdjacentHTML('beforeend', scriptElementFooterParsed.innerHTML);
      }
    }
  }, [form]);

  const sendEmailConfirmation = () => {
    sendEmail({
      variables: {
        email: getFormJSONValue(quoteJSON, "LeadDetailsSectionEmail"),
        cost: formCost,
        quote: quoteJSON,
        workspaceId: currentWorkspaceId,
        formId: formId,
        action: "",
        whiteLabel: hasWhiteLabel,
        emailFollowup: get(form.schema, ["options", "emailFollowup"], "active"),
        isConfirmationEmail: true,
      }
    }).then(({ data, errors }) => {
      if (errors) setEmailError(errors[0].message);
    });
  };

  return (
    <>
      {form.schema && form.schema.options ? (
        <>
          <NotificationAlert ref={notificationAlert} />
          <div className="position-relative edit-container">
            <ThemeProvider theme={theme}>
              {autoSaved || !saved ? (
                <>
                  <FormProvider schema={form.schema} setAllFields={setAllFields} inputFontsize={inputFontsize}>
                    <RenderFormContainer
                      className="sinapiForm"
                      schema={form.schema}
                      sections={sectionsWithoutEmpty}
                      isNotEmptySection={isNotEmptySection}
                      saveLeadAction={saveLeadAction}
                      validateForm={validateForm}
                      notificationAlert={notificationAlert}
                      notificationOptions={notificationOptions}
                      activeSection={activeSection}
                      canceledSubscription={isCanceledOrExpired}
                      formError={formError}
                      setFormError={setFormError}
                    />
                  </FormProvider>
                </>
              ) : (
                <FormSuccessPageSection
                  successPageData={form.schema.success ? form.schema.success : {}}
                  formType={formType}
                  cost={formCost}
                  maxWidth={get(form.schema, ["options", "width"], 700)}
                  quoteJSON={quoteJSON}
                  notInterested={notInterestedAction}
                  onConfirm={onConfirmAction}
                  startOverAction={startOverAction}
                  confirmationMSG={confirmationMSG}
                  backAction={() => {
                    setSaved(false);
                    setActiveSection(sectionsWithoutEmpty.length - 1);
                  }}
                  sendEmailConfirmation={sendEmailConfirmation}
                />
              )}
            </ThemeProvider>
          </div>
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
}

export default FormBuilder;
