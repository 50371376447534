import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { gql } from "apollo-boost-upload";
import { find, get, isEmpty } from "lodash";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
// components
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  FormGroup,
  Button,
  UncontrolledTooltip,
  Input
} from "reactstrap";

import BasicHeader from "components/Headers/BasicHeader.jsx";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import ManagementModal from "components/UserManagement/ManagementModal";
import { useUrlQuery } from "hooks/useUrlQuery";
import { useHistory } from "react-router";
import connectorsConfig from "../../../connectorsConfig.js";
import { useCompanySubscription } from "hooks/useCompanySubscription";
import { getLimitSubscriptionPerFeatureType } from "utils/helperFunctions";
import { FEATURE_CONNECTORS } from "utils/constants";

const GET_CONNECTORS = gql`
  query GetConnectors($companyId: ID!) {
    connectorPerCompany(companyId: $companyId) {
      id
      name
      logoPath
      companyConnectorId
      redirectUrl
      token
      authorizeUrl
      account
      status
    }
  }
`;

const SAVE_COMPANY_CONNECTOR = gql`
  mutation SaveCompanyConnector($companyConnectorId: ID, $companyConnector: CompanyConnectorInput!) {
    saveCompanyConnector(companyConnectorId: $companyConnectorId, companyConnector: $companyConnector) {
      id
    }
  }
`;

const REMOVE_COMPANY_CONNECTOR = gql`
  mutation RemoveCompanyConnector($companyConnectorId: ID) {
    removeCompanyConnector(companyConnectorId: $companyConnectorId)
  }
`;

const GET_QUICKBOOK_AUTH_URL = gql`
  query GetQuickbookAuthURL($companyId: ID!) {
    getQuickbookAuthURL(companyId: $companyId) {
      authorizeUrl
    }
  }
`;

const LOGOS = {
  Hubspot: {
    path: require("assets/img/hubspot-logo.png")
  },
  Salesforce: {
    path: require("assets/img/salesforce-logo.png")
  },
  Xero: {
    path: require("assets/img/xero-logo.png")
  },
  Zapier: {
    path: require("assets/img/zapier-logo.png")
  },
  Keap: {
    path: require("assets/img/connector-logo-keap.png")
  },
  Quickbook: {
    path: require("assets/img/quickbook-logo.png")
  },
  Webhook: {
    path: require("assets/img/webhook-logo.png")
  },
  GoHighLevel: {
    path: require("assets/img/highlevel-logo.png")
  }
};

const ManageConnectors = () => {
  const history = useHistory();
  const query = useUrlQuery();
  const code = query.get("code");
  const connector = query.get("connector");
  const realmId = query.get("realmId");
  const scope = query.get("scope");
  const { currentUser } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalWebhook, setShowModalWebhook] = useState(false);
  const [webhookData, setWebhookData] = useState({
    companyConnectorId: "",
    companyId: "",
    connectorId: "",
    webhookUrl: "",
    webhookUsername: "",
    webhookPassword: ""
  });
  const [webhookFormErrors, setWebhookFormErrors] = useState({
    webhookUrl: ""
  });

  const companySubscription = useCompanySubscription(
    get(currentUser, ["company", "id"]),
    get(currentUser, ["company", "planId"])
  );

  const hasConnectors = getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_CONNECTORS);

  useEffect(() => {
    if (typeof hasConnectors !== "undefined") {
      if (hasConnectors === "0") {
        history.push("/admin/dashboard");
      }
    }
  }, [hasConnectors]);

  const { loading, data, refetch } = useQuery(GET_CONNECTORS, {
    variables: { companyId: currentUser.company.id },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [getQuickbookAuthURL, { data: quickbookAuthURL }] = useLazyQuery(GET_QUICKBOOK_AUTH_URL, {
    variables: { companyId: currentUser.company.id }
  });

  useEffect(() => {
    if (typeof quickbookAuthURL !== "undefined") {
      console.log(quickbookAuthURL?.getQuickbookAuthURL?.authorizeUrl);
      window.location.href = quickbookAuthURL?.getQuickbookAuthURL?.authorizeUrl;
    }
  }, [quickbookAuthURL]);

  const [saveCompanyConnector] = useMutation(SAVE_COMPANY_CONNECTOR, {
    onCompleted: () => {
      refetch();
    }
  });

  const [removeMutation] = useMutation(REMOVE_COMPANY_CONNECTOR, {
    onCompleted: () => {
      setShowModal(false);
      refetch();
    }
  });

  const connectorsData = !loading && data ? data.connectorPerCompany : null;

  const onClose = () => {
    setShowModal(false);
    setShowModalSuccess(false);
    setShowModalWebhook(false);
    setWebhookFormErrors({
      webhookUrl: ""
    });
    setWebhookData({
      webhookUrl: "",
      webhookUsername: "",
      webhookPassword: ""
    });
  };

  const onClickConnect = (url, authorizeUrl, clientId, scope) => {
    window.location.href = authorizeUrl
      .replace("CLIENT_ID", clientId)
      .replace("REDIRECT_URL", url)
      .replace("SCOPE", scope);
  };

  const onClickKeapConnect = (authorizeUrl, clientId, redirectUrl) => {
    window.location.href = authorizeUrl.replace("CLIENT_ID", clientId).replace("REDIRECT_URL", redirectUrl);
  };

  const onClickGoHighLevelConnect = (authorizeUrl, clientId, redirectUrl) => {
    window.location.href = authorizeUrl.replace("CLIENT_ID", clientId).replace("REDIRECT_URL", redirectUrl);
  };

  const onClickQuickbookConnect = () => {
    getQuickbookAuthURL();
  };

  const openZapier = () => window.open("https://zapier.com/app/login", "_blank");

  useEffect(() => {
    console.log(code);
    console.log(connector);
    if (code && connector) {
      const companyConnector = find(connectorsData, (connectorData) => connectorData.name === connector);
      if (companyConnector) {
        saveCompanyConnector({
          variables: {
            companyConnectorId: companyConnector.companyConnectorId,
            companyConnector: {
              companyId: currentUser.company.id,
              connectorId: companyConnector.id,
              token: code,
              callbackUrl: window.location.href,
              name: connector,
              realmId: realmId
            }
          }
        }).then(() => history.push("/admin/connectors"));
      }
    }
    if (code && scope) {
      const companyConnector = find(connectorsData, (connectorData) => connectorData.name === "Keap");
      if (companyConnector) {
        saveCompanyConnector({
          variables: {
            companyConnectorId: companyConnector.companyConnectorId,
            companyConnector: {
              companyId: currentUser.company.id,
              connectorId: companyConnector.id,
              token: code,
              name: companyConnector.name
            }
          }
        }).then(() => history.push("/admin/connectors"));
      }
    }
  }, [code, connector, connectorsData, scope]);

  const ShowToken = ({ token }) => {
    return (
      <>
        {" "}
        with <span className="text-muted">{token}</span> token.
      </>
    );
  };

  const editConnectNotZapier = (connector, name) => {
    if (name !== "Keap" && name !== "Quickbook" && name !== "GoHighLevel") {
      onClickConnect(
        connector.redirectUrl,
        connector.authorizeUrl,
        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
        get(connectorsConfig, [connector.name, "SCOPE"])
      );
    } else if (name === "Quickbook") {
      onClickQuickbookConnect();
    } else if (name === "GoHighLevel") {
      onClickGoHighLevelConnect(
        connector.authorizeUrl,
        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
      );
    } else {
      onClickKeapConnect(
        connector.authorizeUrl,
        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
      );
    }
  };

  const editConnectWithZapier = (connector) => {
    setShowModalSuccess({
      title: "Zapier",
      subtitle: "The next step is to login to Zapier and create your first ZAP",
      action: openZapier
    });
  };

  const onSaveWebhookDetails = () => {
    if (validateForm()) {
      saveCompanyConnector({
        variables: {
          companyConnectorId: webhookData?.companyConnectorId,
          companyConnector: {
            companyId: currentUser.company.id,
            connectorId: webhookData?.connectorId,
            webhookUrl: webhookData?.webhookUrl,
            webhookUsername: webhookData?.webhookUsername,
            webhookPassword: webhookData?.webhookPassword,
            name: "Webhook"
          }
        }
      }).then(() => history.push("/admin/connectors"));
      setShowModalWebhook(false);
    }
  };

  const setWebhookDisconnect = (companyConnectorId, companyId, connectorId) => {
    saveCompanyConnector({
      variables: {
        companyConnectorId,
        companyConnector: {
          companyId,
          connectorId,
          webhookUrl: "",
          webhookUsername: "",
          webhookPassword: "",
          name: "Webhook"
        }
      }
    }).then(() => history.push("/admin/connectors"));
  };

  const validateForm = () => {
    let isValid = true;
    const newFormErrors = { ...webhookFormErrors };

    // Validate webhookUrl
    if (webhookData.webhookUrl.trim() === "") {
      newFormErrors.webhookUrl = "URL is required.";
      isValid = false;
    } else if (!/^(ftp|http|https):\/\/[^ "]+$/.test(webhookData.webhookUrl)) {
      newFormErrors.webhookUrl = "URL is invalid.";
      isValid = false;
    } else {
      newFormErrors.webhookUrl = "";
    }

    setWebhookFormErrors(newFormErrors);
    return isValid;
  };

  return (
    <>
      <BasicHeader>
        <Row className="mt-2 mb--4">
          <Col className="col-6">
            <h1 className="text-white d-inline-block">Connectors</h1>
          </Col>
        </Row>
      </BasicHeader>
      {loading ? <LoaderSpinner /> : null}
      {hasConnectors === "1" && (
        <Container className="mt--5" fluid>
          <div className="header-body">
            <Row>
              {!isEmpty(connectorsData)
                ? connectorsData.map((connector, index) => {
                    if (connector?.status) {
                      return (
                        <Col key={`${connector}_${index}`} md="6" xl="3">
                          <Card
                            className="card-stats"
                            style={{
                              cursor: "pointer",
                              border:
                                connector.token || connector?.account?.webhookUrl
                                  ? "2px solid rgba(45, 205, 155, 0.65)"
                                  : "unset"
                            }}
                          >
                            <CardBody>
                              <Row>
                                <Col className="col">
                                  <div className="text-center">
                                    <img
                                      alt="..."
                                      style={{ maxHeight: "120px" }}
                                      src={get(LOGOS, [connector.name, "path"])}
                                    />
                                  </div>
                                  <div className="mt-2">
                                    {connector.name !== "Webhook" && (
                                      <p className="p-0 m-0 font-size-14">
                                        Status:{" "}
                                        <span
                                          className={`font-weight-bold ${
                                            connector.token ||
                                            (connector.companyConnectorId && connector.name === "Zapier")
                                              ? "text-green"
                                              : ""
                                          }`}
                                        >
                                          {connector.token
                                            ? "Active"
                                            : connector.companyConnectorId && connector.name === "Zapier"
                                            ? "Active"
                                            : "Inactive"}
                                        </span>
                                      </p>
                                    )}
                                    {connector.name === "Webhook" && (
                                      <p className="p-0 m-0 font-size-14">
                                        Status:{" "}
                                        <span
                                          className={`font-weight-bold ${
                                            connector.account.webhookUrl ? "text-green" : ""
                                          }`}
                                        >
                                          {connector.account.webhookUrl ? "Active" : "Inactive"}
                                        </span>
                                      </p>
                                    )}
                                    {connector.token && connector.name !== "Zapier" && connector.name !== "Webhook" && (
                                      <>
                                        <p className="p-0 m-0 font-size-14">Connected Account</p>
                                        {connector?.account?.email && (
                                          <p className="p-0 m-0 font-size-14 account-border">
                                            <span className="pl-1 font-weight-bold">{connector.account.email}</span>
                                          </p>
                                        )}
                                        <Button
                                          id={`edit-` + connector.name}
                                          size="sm mt-3"
                                          color="primary"
                                          onClick={() => editConnectNotZapier(connector, connector.name)}
                                        >
                                          <i className="fas fa-edit" />
                                        </Button>
                                        <UncontrolledTooltip target={`edit-` + connector.name}>
                                          Edit
                                        </UncontrolledTooltip>

                                        <Button
                                          id={`disconnect-` + connector.name}
                                          size="sm mt-3"
                                          color="primary"
                                          onClick={(e) => {
                                            connector.token
                                              ? setShowModal(connector)
                                              : connector.name !== "Zapier"
                                              ? saveCompanyConnector({
                                                  variables: {
                                                    companyConnectorId: connector.companyConnectorId,
                                                    companyConnector: {
                                                      companyId: currentUser.company.id,
                                                      connectorId: connector.id
                                                    }
                                                  }
                                                }).then(() => {
                                                  if (
                                                    connector.name !== "Keap" &&
                                                    connector.name !== "Quickbook" &&
                                                    connector.name !== "GoHighLevel"
                                                  ) {
                                                    onClickConnect(
                                                      connector.redirectUrl,
                                                      connector.authorizeUrl,
                                                      get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                      get(connectorsConfig, [connector.name, "SCOPE"])
                                                    );
                                                  } else if (connector.name === "Quickbook") {
                                                    onClickQuickbookConnect(e);
                                                  } else if (connector.name === "GoHighLevel") {
                                                    onClickGoHighLevelConnect(
                                                      connector.authorizeUrl,
                                                      get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                      get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                    );
                                                  } else {
                                                    onClickKeapConnect(
                                                      connector.authorizeUrl,
                                                      get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                      get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                    );
                                                  }
                                                })
                                              : connector.name === "Zapier" && connector.companyConnectorId
                                              ? setShowModal(connector)
                                              : saveCompanyConnector({
                                                  variables: {
                                                    companyConnectorId: connector.companyConnectorId,
                                                    companyConnector: {
                                                      companyId: currentUser.company.id,
                                                      connectorId: connector.id
                                                    }
                                                  }
                                                }).then(() => {
                                                  setShowModalSuccess({
                                                    title: "Zapier",
                                                    subtitle:
                                                      "The next step is to login to Zapier and create your first ZAP",
                                                    action: openZapier
                                                  });
                                                });
                                          }}
                                        >
                                          <i className="fas fa-link" />
                                          <span>Disconnect</span>
                                        </Button>
                                        <UncontrolledTooltip target={`disconnect-` + connector.name}>
                                          Disconnect
                                        </UncontrolledTooltip>
                                      </>
                                    )}
                                    {!connector.token &&
                                      connector.name !== "Zapier" &&
                                      connector.name !== "Webhook" && (
                                        <>
                                          <Button
                                            id={`connect-` + connector.name}
                                            size="sm mt-3"
                                            color="primary"
                                            onClick={(e) => {
                                              connector.token
                                                ? setShowModal(connector)
                                                : connector.name !== "Zapier"
                                                ? saveCompanyConnector({
                                                    variables: {
                                                      companyConnectorId: connector.companyConnectorId,
                                                      companyConnector: {
                                                        companyId: currentUser.company.id,
                                                        connectorId: connector.id
                                                      }
                                                    }
                                                  }).then(() => {
                                                    if (
                                                      connector.name !== "Keap" &&
                                                      connector.name !== "Quickbook" &&
                                                      connector.name !== "GoHighLevel"
                                                    ) {
                                                      onClickConnect(
                                                        connector.redirectUrl,
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "SCOPE"])
                                                      );
                                                    } else if (connector.name === "Quickbook") {
                                                      onClickQuickbookConnect(e);
                                                    } else if (connector.name === "GoHighLevel") {
                                                      onClickGoHighLevelConnect(
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                      );
                                                    } else {
                                                      onClickKeapConnect(
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                      );
                                                    }
                                                  })
                                                : connector.name === "Zapier" && connector.companyConnectorId
                                                ? setShowModal(connector)
                                                : saveCompanyConnector({
                                                    variables: {
                                                      companyConnectorId: connector.companyConnectorId,
                                                      companyConnector: {
                                                        companyId: currentUser.company.id,
                                                        connectorId: connector.id
                                                      }
                                                    }
                                                  }).then(() => {
                                                    setShowModalSuccess({
                                                      title: "Zapier",
                                                      subtitle:
                                                        "The next step is to login to Zapier and create your first ZAP",
                                                      action: openZapier
                                                    });
                                                  });
                                            }}
                                          >
                                            <i className="fas fa-link" />
                                            <span>Connect</span>
                                          </Button>
                                          <UncontrolledTooltip target={`connect-` + connector.name}>
                                            Connect
                                          </UncontrolledTooltip>
                                        </>
                                      )}

                                    {!connector.token &&
                                      connector.companyConnectorId &&
                                      connector.name === "Zapier" && (
                                        <>
                                          <p className="p-0 m-0 font-size-14">Connected Account</p>
                                          {connector?.account?.email && (
                                            <p className="p-0 m-0 font-size-14 account-border">
                                              <span className="pl-1 font-weight-bold">{connector.account.email}</span>
                                            </p>
                                          )}
                                          <Button
                                            id={`edit-` + connector.name}
                                            size="sm mt-3"
                                            color="primary"
                                            onClick={() => editConnectWithZapier(connector)}
                                          >
                                            <i className="fas fa-edit" />
                                          </Button>
                                          <UncontrolledTooltip target={`edit-` + connector.name}>
                                            Edit
                                          </UncontrolledTooltip>

                                          <Button
                                            id={`disconnect-` + connector.name}
                                            size="sm mt-3"
                                            color="primary"
                                            onClick={() => {
                                              connector.token
                                                ? setShowModal(connector)
                                                : connector.name !== "Zapier"
                                                ? saveCompanyConnector({
                                                    variables: {
                                                      companyConnectorId: connector.companyConnectorId,
                                                      companyConnector: {
                                                        companyId: currentUser.company.id,
                                                        connectorId: connector.id
                                                      }
                                                    }
                                                  }).then(() => {
                                                    if (connector.name !== "Keap" && connector.name !== "GoHighLevel") {
                                                      onClickConnect(
                                                        connector.redirectUrl,
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "SCOPE"])
                                                      );
                                                    } else if (connector.name === "GoHighLevel") {
                                                      onClickGoHighLevelConnect(
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                      );
                                                    } else {
                                                      onClickKeapConnect(
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                      );
                                                    }
                                                  })
                                                : connector.name === "Zapier" && connector.companyConnectorId
                                                ? setShowModal(connector)
                                                : saveCompanyConnector({
                                                    variables: {
                                                      companyConnectorId: connector.companyConnectorId,
                                                      companyConnector: {
                                                        companyId: currentUser.company.id,
                                                        connectorId: connector.id
                                                      }
                                                    }
                                                  }).then(() => {
                                                    setShowModalSuccess({
                                                      title: "Zapier",
                                                      subtitle:
                                                        "The next step is to login to Zapier and create your first ZAP",
                                                      action: openZapier
                                                    });
                                                  });
                                            }}
                                          >
                                            <i className="fas fa-link" />
                                            <span>Disconnect</span>
                                          </Button>
                                          <UncontrolledTooltip target={`disconnect-` + connector.name}>
                                            Disconnect
                                          </UncontrolledTooltip>
                                        </>
                                      )}
                                    {!connector.token &&
                                      !connector.companyConnectorId &&
                                      connector.name === "Zapier" && (
                                        <>
                                          <Button
                                            id={`connect-` + connector.name}
                                            size="sm mt-3"
                                            color="primary"
                                            onClick={() => {
                                              connector.token
                                                ? setShowModal(connector)
                                                : connector.name !== "Zapier"
                                                ? saveCompanyConnector({
                                                    variables: {
                                                      companyConnectorId: connector.companyConnectorId,
                                                      companyConnector: {
                                                        companyId: currentUser.company.id,
                                                        connectorId: connector.id
                                                      }
                                                    }
                                                  }).then(() => {
                                                    if (connector.name !== "Keap" && connector.name !== "GoHighLevel") {
                                                      onClickConnect(
                                                        connector.redirectUrl,
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "SCOPE"])
                                                      );
                                                    } else if (connector.name === "GoHighLevel") {
                                                      onClickGoHighLevelConnect(
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                      );
                                                    } else {
                                                      onClickKeapConnect(
                                                        connector.authorizeUrl,
                                                        get(connectorsConfig, [connector.name, "CLIENT_ID"]),
                                                        get(connectorsConfig, [connector.name, "REDIRECT_URL"])
                                                      );
                                                    }
                                                  })
                                                : connector.name === "Zapier" && connector.companyConnectorId
                                                ? setShowModal(connector)
                                                : saveCompanyConnector({
                                                    variables: {
                                                      companyConnectorId: connector.companyConnectorId,
                                                      companyConnector: {
                                                        companyId: currentUser.company.id,
                                                        connectorId: connector.id
                                                      }
                                                    }
                                                  }).then(() => {
                                                    setShowModalSuccess({
                                                      title: "Zapier",
                                                      subtitle:
                                                        "The next step is to login to Zapier and create your first ZAP",
                                                      action: openZapier
                                                    });
                                                  });
                                            }}
                                          >
                                            <i className="fas fa-link" />
                                            <span>Connect</span>
                                          </Button>
                                          <UncontrolledTooltip target={`connect-` + connector.name}>
                                            Connect
                                          </UncontrolledTooltip>
                                        </>
                                      )}

                                    {connector?.name === "Webhook" && (
                                      <>
                                        {connector?.account?.webhookUrl && (
                                          <>
                                            <Button
                                              id={`edit-` + connector.name}
                                              size="sm mt-3"
                                              color="primary"
                                              onClick={() => {
                                                setWebhookData({
                                                  webhookUrl: connector?.account?.webhookUrl,
                                                  webhookUsername: connector?.account?.webhookUsername,
                                                  webhookPassword: connector?.account?.webhookPassword,
                                                  companyConnectorId: connector.companyConnectorId,
                                                  companyId: currentUser.company.id,
                                                  connectorId: connector.id
                                                });
                                                setShowModalWebhook(true);
                                              }}
                                            >
                                              <i className="fas fa-edit" />
                                            </Button>
                                            <UncontrolledTooltip target={`edit-` + connector.name}>
                                              Edit
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                        <Button
                                          id={`btn-` + connector.name}
                                          size="sm mt-3"
                                          color="primary"
                                          onClick={() => {
                                            if (!connector?.account?.webhookUrl) {
                                              setWebhookData({
                                                webhookUrl: connector?.account?.webhookUrl,
                                                webhookUsername: connector?.account?.webhookUsername,
                                                webhookPassword: connector?.account?.webhookPassword,
                                                companyConnectorId: connector.companyConnectorId,
                                                companyId: currentUser.company.id,
                                                connectorId: connector.id
                                              });
                                              setShowModalWebhook(true);
                                            } else {
                                              setWebhookDisconnect(
                                                connector.companyConnectorId,
                                                currentUser.company.id,
                                                connector.id
                                              );
                                            }
                                          }}
                                        >
                                          <i className="fas fa-link" />
                                          {!connector?.account?.webhookUrl && <span>Connect</span>}
                                          {connector?.account?.webhookUrl && <span>Disconnect</span>}
                                        </Button>
                                        {!connector?.account?.webhookUrl && (
                                          <UncontrolledTooltip target={`btn-` + connector.name}>
                                            Connect
                                          </UncontrolledTooltip>
                                        )}
                                        {connector?.account?.webhookUrl && (
                                          <UncontrolledTooltip target={`btn-` + connector.name}>
                                            Disconnect
                                          </UncontrolledTooltip>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    }
                  })
                : null}
            </Row>
          </div>
        </Container>
      )}

      {showModal && (
        <ManagementModal
          modalSize="lg"
          onClose={() => onClose()}
          header={<div>{showModal.name.toUpperCase()} CONNECTION</div>}
          buttons={[
            {
              label: `Access ${showModal.name}`,
              color: "success",
              icon: "fas fa-globe mr-2",
              onClick: () => openZapier(),
              visible: showModal.name === "Zapier" && showModal.companyConnectorId ? true : false
            },
            {
              label: "Delete",
              color: "danger",
              icon: "fas fa-trash mr-2",
              onClick: () =>
                removeMutation({
                  variables: { companyConnectorId: showModal.companyConnectorId }
                }),
              visible: showModal.companyConnectorId ? true : false
            },
            {
              label: "Close",
              onClick: () => onClose(),
              icon: "fas fa-times mr-2"
            }
          ]}
        >
          <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
            <h2 className="font-weight-400 text-justify">
              You already have a connection to {showModal.name}
              {showModal.token ? <ShowToken token={showModal.token} /> : "."} Would you like to delete it?
            </h2>
          </CardHeader>
        </ManagementModal>
      )}
      {showModalSuccess && (
        <ManagementModal
          modalSize="lg"
          onClose={() => onClose()}
          header={<div>{showModalSuccess.title.toUpperCase()} CONNECTION</div>}
          buttons={[
            {
              label: "Close",
              onClick: () => onClose()
            }
          ]}
        >
          <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
            <h2 className="font-weight-400 text-center">
              Your account is now set up to use {showModalSuccess.title}.<br />
              {showModalSuccess.subtitle}
            </h2>
          </CardHeader>
          <Col className="text-center" xs="12">
            <Button color="primary" onClick={() => showModalSuccess.action()}>
              Access {showModalSuccess.title}
            </Button>
          </Col>
        </ManagementModal>
      )}
      {showModalWebhook && (
        <ManagementModal
          modalSize="md"
          onClose={() => onClose()}
          header={<div>WEBHOOK CONNECTION</div>}
          buttons={[
            {
              label: "Save",
              onClick: () => onSaveWebhookDetails(),
              className: "btn btn-success btn-lg"
            },
            {
              label: "Close",
              onClick: () => onClose()
            }
          ]}
        >
          <CardHeader className="bg-transparent pl-2 pt-0 pr-2 border-0">
            <h2 className="font-weight-400">
              Enter details below for the webhook where Sinapi will post form data on submission
            </h2>
          </CardHeader>
          <Col xs="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="custom-web-url">
                URL
              </label>
              <Input
                name="webhookUrl"
                id="custom-web-url"
                className="form-control"
                value={webhookData?.webhookUrl || ""}
                onChange={(e) => setWebhookData({ ...webhookData, webhookUrl: e.target.value })}
              />
              {webhookFormErrors?.webhookUrl && <small className="text-red">{webhookFormErrors?.webhookUrl}</small>}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="custom-web-username">
                Username
              </label>
              <Input
                name="webhookUsername"
                id="custom-web-username"
                className="form-control"
                value={webhookData?.webhookUsername || ""}
                onChange={(e) => setWebhookData({ ...webhookData, webhookUsername: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="custom-web-pass">
                Password
              </label>
              <Input
                name="webhookPassword"
                id="custom-web-pass"
                className="form-control"
                value={webhookData?.webhookPassword || ""}
                onChange={(e) => setWebhookData({ ...webhookData, webhookPassword: e.target.value })}
              />
            </FormGroup>
          </Col>
        </ManagementModal>
      )}
    </>
  );
};

export default ManageConnectors;
