import React from "react";
import { useSelector } from "react-redux";

import { gql } from "apollo-boost-upload";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";
import Switch from "react-switch";
import { useHistory } from "react-router";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  FormGroup
} from "reactstrap";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

const GET_CONNECTORS = gql`
  query GetConnectors($companyId: ID!) {
    connectorPerCompany(companyId: $companyId) {
      id
      name
      logoPath
      companyConnectorId
      redirectUrl
      token
      authorizeUrl
      account
      status
    }
  }
`;

const SAVE_COMPANY_CONNECTOR = gql`
  mutation UpdateConnectorStatus($connectorId: ID, $connector: CompanyConnectorInput!) {
    updateConnectorStatus(connectorId: $connectorId, connector: $connector) {
      id
    }
  }
`;

const AdminManageConnect = () => {

  const { currentUser } = useSelector((state) => state.user);
  const history = useHistory();

  const { loading, data, refetch } = useQuery(GET_CONNECTORS, {
    variables: { companyId: currentUser.company.id },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [saveCompanyConnector] = useMutation(SAVE_COMPANY_CONNECTOR, {
    onCompleted: () => {
      refetch();
    }
  });

  const connectorsData = !loading && data ? data.connectorPerCompany : null;

  const setToggleStatus = (id, status) => {
    console.log(id);

    saveCompanyConnector({
      variables: {
        connectorId: id,
        connector: {
          status: status,
        }
      }
    }).then(() => history.push("/manage/manage-connectors"));
  }

  return(
      <>
        <SimpleHeader name="Manage" parentName="Manage" />
        {loading ? <LoaderSpinner /> : null}
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Connectors</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table hover className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Hidden</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {isEmpty(connectorsData) && (
                    <tr>
                      <td>
                        <div className="m-4">{`No CRM Services.`}</div>
                      </td>
                    </tr>
                  )}
                  {!isEmpty(connectorsData) && (
                    connectorsData.map((connector, index) => (
                      <tr key={connector.id} >
                          <td>{connector.name}</td>
                          <td>
                            <FormGroup className="mb-0 ml-2">
                              <Switch
                                onColor="#11cdef"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                className="react-switch"
                                checked={connector?.status ? true : false}
                                onChange={() => setToggleStatus(connector?.id, !connector?.status)}
                              />
                            </FormGroup>
                          </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        </Container>
      </>
  )
}

export default AdminManageConnect;
