import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  FormGroup,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
  CardFooter,
  Label,
  FormFeedback
} from "reactstrap";
import { useDispatch } from "react-redux";
import { formActions } from "reducers";
import { get, isEqual, toLower } from "lodash";
import { FORM_NAME } from "utils/constants";
import { getUniqueID } from "./fields/HelperFunctions";

// ***** The added element component ****** //
const AddedEmailElement = ({ idx, objEmail, onDelete, onChange, onBlur }) => (
  <FormGroup className="mb-2">
    <div style={{ display: "-webkit-box" }}>
      <Button
        size="sm"
        className="btn-icon-only rounded-circle"
        color="dark"
        type="button"
        onClick={() => onDelete(idx)}
      >
        <span className="btn-inner--icon">
          <i className="ni ni-lg ni-fat-delete" />
        </span>
      </Button>
      <Input
        className="form-control-sm"
        invalid={objEmail.error}
        placeholder="Write the email"
        type="email"
        defaultValue={objEmail.email}
        onChange={({ target }) => onChange(idx, target.value)}
        onBlur={() => onBlur()}
      />
      <FormFeedback className="mt-2 col-2">
        <i className="fas fa-exclamation-triangle" title="Invalid email address" />
      </FormFeedback>
    </div>
  </FormGroup>
);

function FormSettingsPanel({
  formOptions,
  onClose,
  formId,
  emails,
  setEmails,
  onSaveEmails,
  onChange,
  onDelete,
  emailsError
}) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState({ ...(formOptions || {}) });
  const [prevOptions] = useState({ ...(formOptions || {}) });

  const onCancel = () => {
    if (!isDirty) {
      dispatch({
        type: formActions.UPDATE_FORM_OPTIONS,
        payload: prevOptions
      });
    }
    onClose();
  };

  const onSave = () => {
    if (!emailsError()) {
      onSaveEmails();
      dispatch({
        type: formActions.UPDATE_FORM_OPTIONS,
        payload: options
      });
      onClose();
    }
  };

  const changeOptionInput = (e) => {
    setOptions({
      ...options,
      [e.target.name]: e.target.value
    });
    dispatch({
      type: formActions.UPDATE_FORM_OPTIONS,
      payload: {
        ...options,
        [e.target.name]: e.target.value
      }
    });
  };

  const onRadioButtonChange = (e, type) => {
    setOptions({
      ...options,
      [type]: e.target.name
    });
  };

  const isDirty = isEqual(prevOptions, options);

  return (
    <div className="vertical-sidenav-full-container">
      <Card className="card-pricing border-0 mb-0">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="12" className="vertical-sidenav-header">
              <Button close onClick={onCancel} />
              <h1 className="mb-0">
                <i className="fas fa-cog" /> Settings
              </h1>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <FormGroup>
            <label className="form-control-label">
              {FORM_NAME} Width
              <FormText color="muted">
                How wide do you want your {toLower(FORM_NAME)} to be by default. It will never exceed the width of the
                page or squish your content.
              </FormText>
            </label>
            <InputGroup size="sm">
              <Input
                placeholder="px"
                className="form-control-sm col-2"
                type="number"
                name="width"
                defaultValue={options && options.width}
                onChange={(e) => changeOptionInput(e)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>px</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              {FORM_NAME} Type
              <FormText color="muted">
                This option will either display the {toLower(FORM_NAME)} on a single page or a step through.
              </FormText>
            </label>
            <div className="form-inline">
              <Label check className="form-check">
                <Input
                  type="radio"
                  name="single-page"
                  checked={options ? options.type === "single-page" : false}
                  onChange={(e) => onRadioButtonChange(e, "type")}
                />{" "}
                Single Page
              </Label>
              <Label check className="form-check ml-2">
                <Input
                  type="radio"
                  name="multi-step"
                  checked={options ? options.type === "multi-step" : false}
                  onChange={(e) => onRadioButtonChange(e, "type")}
                />{" "}
                Multi-step
              </Label>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              Notification Emails
              <FormText color="muted">
                Manage the members of your team who will receive the notification each time and end user fill out the{" "}
                {toLower(FORM_NAME)}.
              </FormText>
            </label>
            <Row className="mb-4">
              <Col lg="8" xs="12">
                {emails &&
                  emails.length > 0 &&
                  emails.map((objEmail, idx) => (
                    <AddedEmailElement
                      key={getUniqueID() + idx}
                      idx={idx}
                      objEmail={objEmail}
                      onChange={onChange}
                      onDelete={onDelete}
                      onBlur={emailsError}
                    />
                  ))}
                <Button size="sm" color="info" onClick={() => setEmails([...emails, { formId, email: "" }])}>
                  <i className="fas fa-plus" /> Add Email
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              Notification Frequency
              <FormText color="muted">
                This determines how often you get notified when leads fill out your form.
              </FormText>
            </label>
            <div className="form-inline">
              <Label check className="form-check">
                <Input
                  type="radio"
                  name="instantly"
                  checked={options ? get(options, "frequency", "instantly") === "instantly" : false}
                  onChange={(e) => onRadioButtonChange(e, "frequency")}
                />{" "}
                Instantly
              </Label>
              <Label check className="form-check ml-2">
                <Input
                  type="radio"
                  name="daily"
                  checked={options ? get(options, "frequency", "instantly") === "daily" : false}
                  onChange={(e) => onRadioButtonChange(e, "frequency")}
                />{" "}
                Daily
              </Label>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              Automated Email Followup
              <FormText color="muted">
                When a lead fills out this form, do you want the details of their quote automatically emailed to them?
              </FormText>
            </label>
            <div className="form-inline">
              <Label check className="form-check">
                <Input
                  type="radio"
                  name="active"
                  checked={options ? get(options, "emailFollowup", "active") === "active" : false}
                  onChange={(e) => onRadioButtonChange(e, "emailFollowup")}
                />{" "}
                Active
              </Label>
              <Label check className="form-check ml-2">
                <Input
                  type="radio"
                  name="off"
                  checked={options ? get(options, "emailFollowup", "active") === "off" : false}
                  onChange={(e) => onRadioButtonChange(e, "emailFollowup")}
                />{" "}
                Off
              </Label>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              Custom Code: Header
              <FormText color="muted">The code you add here will be added to the {`< head >`} of your form</FormText>
            </label>
            <Input
              className="form-control-sm"
              name="customCodeHeader"
              type="textarea"
              rows={6}
              defaultValue={options && options.customCodeHeader}
              onChange={(e) => changeOptionInput(e)}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              Custom Code: Footer
              <FormText color="muted">The code you add here will be added to the end of the {`< body >`} tag of your form (good for tracking codes such as google analytics)</FormText>
            </label>
            <Input
              className="form-control-sm"
              name="customCodeFooter"
              type="textarea"
              rows={6}
              defaultValue={options && options.customCodeFooter}
              onChange={(e) => changeOptionInput(e)}
            />
          </FormGroup>
        </CardBody>
        <CardFooter className="card-footer-sidenav">
          <Row className="align-items-center">
            <Col className="text-right" xs="12">
              <Button type="button" color="secondary" onClick={onCancel} className="float-left">
                Cancel
              </Button>
              <Button type="button" color="primary" onClick={onSave}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
}

export { FormSettingsPanel };
