import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "react-jss";
import { formActions } from "reducers";

import moment from "moment";
import { gql } from "apollo-boost";
import { isEmpty, find, findIndex, get } from "lodash";
import { set } from "lodash/fp";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Col, Container, Row } from "reactstrap";
import { CSSTransition } from "react-transition-group";

// core components
import { FormTitleHeader } from "components/Headers/FormTitleHeader";
import { FieldListCard } from "components/FormComponents/FieldListCard";
import { useAutoSave } from "hooks/useAutoSave";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { SinapiDragAndDropProvider } from "components/DragAndDrop";
import { FormContainer } from "components/FormComponents/FormContainer";
import { FieldOptionsPanel } from "components/FormComponents/FieldOptionsPanel";
import { SectionOptionsPanel } from "components/FormComponents/SectionOptionsPanel";
import { FormRevisionHistory } from "components/FormComponents/FormRevisionHistory";
import { SuccessPageOptionsPanel } from "components/FormComponents/SuccessPageOptionsPanel";
import { FormSettingsPanel } from "components/FormComponents/FormSettingsPanel";
import { FormThemePanel } from "components/FormComponents/themes/FormThemePanel";
import { getDefaultFloatinButtonJSON, getDefaultFormJSON, getDefaultSchemaJSON } from "./defaultFormData";
import { VerticalSidenav } from "components/Sidebar/VerticalSidenav";
import { useLocation, useHistory } from "react-router-dom";
import { FormProvider } from "./FormProvider";
import { FormContext } from "./FormContext";
import { validateEmail } from "utils/helperFunctions";
import ManagementModal from "components/UserManagement/ManagementModal";
import PublishCodeContainer from "./publish/PublishCodeContainer";
import { SampleFormContainer } from "components/FormComponents/SampleFormContainer";
import { useCompanySubscription } from "hooks/useCompanySubscription";

import "./edit.scss";
import FormVisualization from "components/FormComponents/FormVisualization";

const UPDATE_FORM = gql`
  mutation UpdateForm(
    $formId: ID
    $workspaceId: ID!
    $form: FormInput!
    $autosave: Boolean
    $published: Boolean
    $hasOptionImage: Boolean
  ) {
    updateForm(
      workspaceId: $workspaceId
      formId: $formId
      form: $form
      autosave: $autosave
      published: $published
      hasOptionImage: $hasOptionImage
    ) {
      id
      name
      schema
      lastUpdate
      activeTheme {
        id
        type
        styleSchema
      }
    }
  }
`;

const UPDATE_FORM_NAME = gql`
  mutation UpdateFormName($formId: ID, $workspaceId: ID!, $formName: String!) {
    updateFormName(workspaceId: $workspaceId, formId: $formId, formName: $formName) {
      id
      name
    }
  }
`;

const GET_FORM = gql`
  query GetForm($workspaceId: ID!, $formId: ID!) {
    workspace(id: $workspaceId) {
      id
      form(id: $formId) {
        id
        name
        description
        display
        schema
        status
        formType
        lastUpdate
        formHash
        workspaceHash
        renderGuid
        buttonSchema
        floatingButtonSchema
        userId
        activeTheme {
          id
          type
          styleSchema
        }
        notificationEmails {
          formId
          email
        }
      }
    }
  }
`;

const ADD_FORM_NOTIFICATION_EMAILS = gql`
  mutation AddFormNotificationEmail($formId: ID!, $emails: [String!]) {
    addFormNotificationEmail(formId: $formId, emails: $emails)
  }
`;

const REMOVE_FORM_NOTIFICATION_EMAILS = gql`
  mutation RemoveFormNotificationEmail($formId: ID!) {
    removeFormNotificationEmail(formId: $formId)
  }
`;

const PUBLISH_FORM = gql`
  mutation PublishForm(
    $formId: ID!
    $schema: JSONObject
    $buttonSchema: JSONObject
    $actionFloatingButtonSchema: JSONObject
    $hasOptionImage: Boolean
  ) {
    publishForm(
      formId: $formId
      schema: $schema
      buttonSchema: $buttonSchema
      actionFloatingButtonSchema: $actionFloatingButtonSchema
      hasOptionImage: $hasOptionImage
    ) {
      formId
      workspaceId
      guid
      userId
    }
  }
`;

const GET_CONNECTORS = gql`
  query GetConnectors($companyId: ID!) {
    connectorPerCompany(companyId: $companyId) {
      id
      name
      logoPath
      companyConnectorId
      redirectUrl
      token
      authorizeUrl
      account
      status
    }
  }
`;

function FormBuilder() {
  // ***** Set Dispatch Variable ****** //
  const formJSONRef = useRef();
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const { formId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const {
    loading: formLoading,
    data: formData,
    refetch
  } = useQuery(GET_FORM, {
    variables: { formId: formId, workspaceId: currentWorkspace.id },
    skip: !formId
  });
  const dispatch = useDispatch();
  const companySubscription = useCompanySubscription(currentUser.company.id, currentUser.company.planId);

  const { loading: loadingGetConnector,data: dataGetConnector } = useQuery(GET_CONNECTORS, {
    variables: { companyId: currentUser.company.id },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const connectorsData = !loadingGetConnector && dataGetConnector ? dataGetConnector.connectorPerCompany : null;

  let hubspotArr;
  if(dataGetConnector) {
    hubspotArr = connectorsData.filter((connector) => {
      return connector.name === "Hubspot";
    })
    if(hubspotArr[0]?.token) {
      localStorage.setItem("hubspotEnabled", true);
    } else {
      localStorage.setItem("hubspotEnabled", false);
    }
  } else {
    localStorage.setItem("hubspotEnabled", false);
  }

  // ***** Set the state vars ****** //
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [updateForm, { data: updatedData, loading: updating }] = useMutation(UPDATE_FORM, {
    onCompleted: () => {
      setEditedButtonSchema(false);
      refetch();
    }
  });
  const [updateFormName] = useMutation(UPDATE_FORM_NAME);
  const {
    formJSON,
    updatedAt,
    isNewForm,
    activeQuestion,
    updatedSections,
    editTheme,
    activeEditOption,
    activeSection
  } = useSelector((state) => state.form);
  const { activeTab, activeQuestionJSON } = activeQuestion;
  const { activeSectionJSON } = activeSection;
  // **** Get Updated live formJSON ****//
  const sectionIndex =
    formJSON && formJSON.schema && formJSON.schema.sections.findIndex((sec) => sec.id === activeQuestion.sectionId);
  const questionPath = [
    "schema",
    "sections",
    sectionIndex,
    "rows",
    activeQuestion.rowIndex,
    "columns",
    activeQuestion.columnIndex,
    "questions"
  ];
  const sectionQuestions = get(formJSON, questionPath, []);
  const questionIndex = findIndex(sectionQuestions, { id: activeQuestionJSON.id });
  const updatedFormJSON = set([...questionPath, questionIndex], activeQuestionJSON, formJSON);

  const getFormSchemaJSON = () =>
    isEmpty(activeQuestionJSON) ? (formJSON && formJSON.schema ? formJSON.schema : null) : updatedFormJSON.schema;

  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  formJSONRef.current = formJSON;

  const [publishForm] = useMutation(PUBLISH_FORM, {
    onCompleted: () => refetch()
  });
  const [addFormNotificationEmail] = useMutation(ADD_FORM_NOTIFICATION_EMAILS);
  const [removeFormNotificationEmail] = useMutation(REMOVE_FORM_NOTIFICATION_EMAILS);

  const [buttonTo, setButtonTo] = useState("popup");
  const [emails, setEmails] = useState();
  const [actionButtonSchema, setActionButtonSchema] = useState();
  const [floatingButtonSchema, setFloatingButtonSchema] = useState();
  const [cssTransition, setCssTransition] = useState();
  const [editedButtonSchema, setEditedButtonSchema] = useState(false);
  const userEmail = currentUser && currentUser.email;
  const [publishFormModal, setPublishFormModal] = useState(null);
  const theme = editTheme ? editTheme.styleSchema : formJSON.activeTheme ? formJSON.activeTheme.styleSchema : {};
  const inputFontsize = get(theme, ["& .form-control", "fontSize"], "14 px");
  const [overviewMode, setOverviewMode] = useState(false);

  useAutoSave(30, updatedSections, () => {
    const sections = get(formJSONRef.current, ["schema", "sections"], []);
    let allFields = [];
    sections.forEach((section) => {
      section.rows.forEach((row) => {
        row.columns.forEach((col) => {
          col.questions.forEach((question) => {
            if (["checkbox", "radio"].includes(question.type)) {
              allFields.push(question);
            }
          });
        });
      });
    });
    saveSection(formJSONRef.current, [], allFields, true);
  });

  useEffect(() => {
    if (updatedData && updatedData.updateForm && !updating) {
      // Update updated at
      dispatch({
        type: "UPDATED_FORM",
        payload: {
          ...updatedData.updateForm,
          schema: {
            options: {
              width: 700
            },
            ...updatedData.updateForm.schema
          }
        }
      });
      if (!formId && updatedData.updateForm.id) {
        history.replace(location.pathname + "/" + updatedData.updateForm.id);
      }
    }
  }, [updatedData]);

  useEffect(() => {
    if (!formLoading && formData && formData.workspace) {
      const newFormData = { ...(formData.workspace.form || getDefaultFormJSON()) };
      if (!newFormData.schema) {
        newFormData.schema = getDefaultFormJSON().schema;
      }
      if (formData.workspace.form.notificationEmails.length > 0) {
        setEmails(formData.workspace.form.notificationEmails);
      } else {
        setEmails([{ formId, email: userEmail }]);
      }
      if (formData.workspace.form.buttonSchema) {
        setActionButtonSchema(formData.workspace.form.buttonSchema);
      }
      if (formData.workspace.form.floatingButtonSchema) {
        setFloatingButtonSchema(formData.workspace.form.floatingButtonSchema);
      } else {
        setFloatingButtonSchema(getDefaultFloatinButtonJSON());
      }

      // const allQuestions = [];
      // newFormData.schema.sections.forEach(section => {
      //   section.rows.forEach(row => {
      //     row.columns.forEach(column => {
      //       column.questions.forEach(question => {
      //         allQuestions.push(question);
      //       })
      //     })
      //   })
      // })
      // newFormData.schema.sections.forEach((section, sIndex) => {
      //   section.rows.forEach((row, rIndex) => {
      //     row.columns.forEach((column, cIndex) => {
      //       column.questions.forEach((question, qIndex) => {
      //         if (question.hasOwnProperty('dynamicRules') && question.dynamicRules.active && ['checkbox', 'radio', 'select'].includes(question.type)) {
      //           question.dynamicRules.rules.forEach((rule, ruleIndex) => {
      //             rule.ruleGroup.rules.forEach((r, ruIndex)=> {
      //               const q = allQuestions.find(q => q.id === r.questionId);
      //               const isMultiple = (['checkbox', 'radio', 'select'].includes(q.type))
      //               if (q && isMultiple) {
      //                 const optionId = q.options.find(opt => opt.name === r.answer || opt.id === r.answer)
      //                 debugger;
      //                 if (optionId.id) {
      //                   s(newFormData.schema, `sections[${sIndex}].rows[${rIndex}].columns[${cIndex}].questions[${qIndex}].dynamicRules.rules[${ruleIndex}].ruleGroup.rules[${ruIndex}].answer`, optionId.id)
      //                 }
      //               }
      //             })
      //           })
      //         }
      //       })
      //     })
      //   })
      // })
      dispatch({
        type: "UPDATE_FORM_JSON",
        payload: {
          ...newFormData,
          schema: {
            options: {
              width: 700
            },
            ...newFormData.schema
          }
        }
      });
    }
  }, [formData]);

  const handleOnChange = (id, value) => {
    emails[id].email = value;
    setEmails(emails);
  };

  const removeEmailElement = (idx) => {
    const before = emails.slice(0, idx);
    const after = emails.slice(idx + 1);
    setEmails([...before, ...after]);
  };

  const emailsWithErrorValue = () => {
    const emailsWithErrors = [];
    emails.map((email) => emailsWithErrors.push({ ...email, error: validateEmail(email.email) ? true : false }));
    setEmails(emailsWithErrors);
    return emailsWithErrors.find((email) => email.error) ? true : false;
  };

  const addFormNotificationEmailsAction = () => {
    removeFormNotificationEmail({ variables: { formId: formId } });
    addFormNotificationEmail({
      variables: {
        formId: formId,
        emails: emails.map((item) => item.email)
      }
    });
  };

  // Get initial form details
  useEffect(() => {
    if (isNewForm && !formId) {
      dispatch({
        type: "UPDATE_FORM_JSON",
        payload: {
          ...getDefaultFormJSON(),
          options: {
            formWidth: "700px"
          }
        }
      });
    }
    return () => {
      dispatch({ type: "RESET_FORM" });
    };
  }, []);

  const selectEditOption = (type) => {
    if (!type)
      dispatch({
        type: formActions.CLOSE_ACTIVE_QUESTION
      });
    else setRightPanelOpen(false);

    dispatch({
      type: "SELECT_ACTIVE_EDIT_OPTION",
      payload: {
        type: type
      }
    });
  };

  const updateActiveTheme = (activeTheme) => {
    dispatch({
      type: formActions.UPDATE_ACTIVE_THEME,
      payload: activeTheme
    });
  };
  const saveSection = (form, allFieldsWithCost, allFields, autosave = false) => {
    const { schema, name, description, display, activeTheme } = form;

    const hasOptionImage = allFields.filter((field) => field.hasOptionImages).length > 0;
    updateForm({
      variables: {
        workspaceId: currentWorkspace.id,
        formId: formId,
        autosave,
        hasOptionImage,
        form: {
          name,
          description,
          display,
          schema,
          actionButtonSchema,
          actionFloatingButtonSchema: floatingButtonSchema,
          themeId: activeTheme ? activeTheme.id : null,
          themeType: activeTheme ? activeTheme.type : null,
          formType:
            autosave || (isNewForm && !formId) ? "quote" : find(allFieldsWithCost, "dollarValue") ? "quote" : "simple"
        }
      }
    });
  };

  const createNewSection = (index) => {
    const newID = "00000" + new Date().valueOf();
    const newSectionJSON = {
      id: newID,
      visible: true,
      title: "New Slide",
      description: "",
      value: "",
      rows: [
        {
          format: [12],
          columns: [
            {
              questions: []
            }
          ]
        }
      ]
    };
    dispatch({
      type: "ADD_NEW_SECTION",
      payload: {
        section: newSectionJSON,
        index: index + 1
      }
    });
  };

  const publishFormAction = (formId, allFields, allFieldsWithCost) => {
    saveSection(formJSON, allFieldsWithCost, allFields);
    const hasOptionImage = allFields.filter((field) => field.hasOptionImages).length > 0;
    publishForm({
      variables: {
        formId: formId,
        schema: formJSON.schema,
        buttonSchema: actionButtonSchema,
        actionFloatingButtonSchema: floatingButtonSchema,
        hasOptionImage: hasOptionImage
      }
    }).then(({ data }) => {
      setConfirmationModal(null);
      setPublishFormModal({ type: "publish", ...data.publishForm });
    });
  };

  return (
    <>
      <div className="header header-dark bg-gradient-info mt--3 pb-3 content__title content__title--calendar border-bottom">
        <Container fluid>
          <div className="header-body">
            <FormTitleHeader
              onChange={(newName) => {
                updateFormName({
                  variables: {
                    workspaceId: currentWorkspace.id,
                    formId: formId,
                    formName: newName
                  }
                });
              }}
              title={formJSON.name}
            />
            {updatedAt ? (
              <div className="updated-container">Updated At: {moment(updatedAt).format("h:mm a")}</div>
            ) : null}
          </div>
        </Container>
      </div>
      <FormProvider schema={formJSON.schema} inputFontsize={inputFontsize}>
        <SinapiDragAndDropProvider>
          <div
            className={classnames("position-relative edit-container", {
              "left-panel-open": activeEditOption && activeEditOption.type,
              "right-panel-open": rightPanelOpen
            })}
          >
            <VerticalSidenav
              location="left"
              onOpen={(type) => selectEditOption(type)}
              onClose={() => {
                selectEditOption(null);
                dispatch({
                  type: formActions.CANCEL_EDIT_THEME
                });
              }}
              activeNav={activeEditOption && activeEditOption.type ? activeEditOption.type : null}
              onMenuItemClick={(item) => {
                if (item === "formOverview") {
                  setOverviewMode(true);
                } else {
                  setOverviewMode(false);
                }
              }}
            >
              <VerticalSidenav.NavIcon name="elements" iconClass="fas fa-plus" title="Questions" />
              <VerticalSidenav.NavIcon name="formThemes" iconClass="fas fa-paint-brush" title="Styles" />
              <VerticalSidenav.NavIcon name="formSettings" iconClass="fas fa-cog" title="Settings" />
              <VerticalSidenav.NavIcon
                name="formOverview"
                iconClass="fas fa-clipboard-list"
                title="Overview"
                hasContent={false}
              />

              <VerticalSidenav.NavContent name="elements">
                {(onClose) => (
                  <FieldListCard
                    onClose={onClose}
                    companySubscription={companySubscription}
                    company={currentUser.company}
                  />
                )}
              </VerticalSidenav.NavContent>
              <VerticalSidenav.NavContent name="editQuestion">
                {(onClose) => (
                  <FieldOptionsPanel
                    activeQuestionJSON={activeQuestionJSON}
                    company={currentUser.company}
                    companySubscription={companySubscription}
                    activeTab={activeTab}
                    onClose={() => onClose()}
                  />
                )}
              </VerticalSidenav.NavContent>
              <VerticalSidenav.NavContent name="editSection">
                {() => (
                  <SectionOptionsPanel
                    activeSectionJSON={activeSectionJSON}
                    company={currentUser.company}
                    companySubscription={companySubscription}
                    onClose={() =>
                      dispatch({
                        type: formActions.CLOSE_ACTIVE_SECTION
                      })
                    }
                  />
                )}
              </VerticalSidenav.NavContent>
              <VerticalSidenav.NavContent name="editSuccessSection">
                {(onClose) => (
                  <SuccessPageOptionsPanel activeQuestionJSON={activeQuestionJSON} onClose={() => onClose()} />
                )}
              </VerticalSidenav.NavContent>
              <VerticalSidenav.NavContent name="formSettings">
                {(onClose) => (
                  <FormSettingsPanel
                    onClose={onClose}
                    formOptions={(formJSON.schema && formJSON.schema.options) || {}}
                    formId={formId}
                    emails={emails}
                    setEmails={setEmails}
                    onSaveEmails={addFormNotificationEmailsAction}
                    onChange={handleOnChange}
                    onDelete={removeEmailElement}
                    emailsError={emailsWithErrorValue}
                  />
                )}
              </VerticalSidenav.NavContent>
              <VerticalSidenav.NavContent name="formThemes">
                {(onClose) => (
                  <FormThemePanel
                    onClose={onClose}
                    activeTab={activeTab}
                    theme={theme}
                    onEditUpdate={(t) => {}}
                    activeTheme={get(formJSON, "activeTheme", {})}
                    onUpdateActiveTheme={updateActiveTheme}
                    actionButtonSchema={actionButtonSchema}
                    setActionButtonSchema={setActionButtonSchema}
                    setEditedButtonSchema={setEditedButtonSchema}
                    setCssTransition={setCssTransition}
                    companySubscription={companySubscription}
                    company={currentUser.company}
                    floatingButtonSchema={floatingButtonSchema}
                    setFloatingButtonSchema={setFloatingButtonSchema}
                  />
                )}
              </VerticalSidenav.NavContent>
            </VerticalSidenav>
            <ThemeProvider theme={theme}>
              {activeEditOption &&
              activeEditOption.type === "formThemes" &&
              (editTheme ? true : false || activeTab === "2") ? (
                <CSSTransition
                  in={
                    activeEditOption &&
                    activeEditOption.type === "formThemes" &&
                    (editTheme ? true : false || activeTab === "2")
                  }
                  appear={
                    activeEditOption &&
                    activeEditOption.type === "formThemes" &&
                    (editTheme ? true : false || activeTab === "2")
                  }
                  timeout={100}
                  onEntered={() => setCssTransition("enter-done")}
                >
                  <SampleFormContainer
                    schema={getDefaultSchemaJSON(true)}
                    buttonSchema={actionButtonSchema}
                    floatingButtonSchema={floatingButtonSchema}
                    showButton={activeTab === "2"}
                    cssTransition={cssTransition}
                  />
                </CSSTransition>
              ) : !overviewMode ? (
                <FormContainer
                  theme={theme}
                  className="sinapiForm"
                  schema={getFormSchemaJSON()}
                  formType={isNewForm && !formId ? "quote" : formJSON.formType}
                  onCreateSection={(index) => createNewSection(index)}
                />
              ) : (
                <FormVisualization schema={getFormSchemaJSON()} setOverviewMode={setOverviewMode} />
              )}
            </ThemeProvider>
            <FormContext.Consumer>
              {({ allFieldsWithCost, allFields }) => (
                <VerticalSidenav
                  location="right"
                  onOpen={() => {
                    selectEditOption(null);
                    setRightPanelOpen(true);
                  }}
                  onClose={() => setRightPanelOpen(false)}
                  onMenuItemClick={(item) => {
                    if (item === "save") {
                      saveSection(formJSON, allFieldsWithCost, allFields);
                    }
                    if (item === "publishing") {
                      formJSON.renderGuid === null
                        ? setConfirmationModal({
                            type: "publish",
                            formId,
                            schema: formJSON.schema,
                            allFields: allFields
                          })
                        : setPublishFormModal({
                            type: "republish",
                            formId: formJSON.formHash,
                            workspaceId: formJSON.workspaceHash,
                            guid: formJSON.renderGuid,
                            userId: formJSON.userId,
                            allFieldsWithCost: allFieldsWithCost,
                            allFields: allFields
                          });
                    }
                  }}
                >
                  <VerticalSidenav.NavIcon
                    name="publishing"
                    iconClass="fab fa-telegram-plane"
                    title="Publish"
                    hasContent={false}
                    disabled={isNewForm && !formId}
                  />
                  <VerticalSidenav.NavIcon
                    name="save"
                    iconClass="fas fa-save"
                    title="Save"
                    hasContent={false}
                    disabled={updatedSections.length > 0 || editedButtonSchema ? false : true}
                  />
                  <VerticalSidenav.NavIcon name="history" iconClass="fas fa-history" title="History" show={!!formId} />
                  <VerticalSidenav.NavContent name="history">
                    {(onClose) => (
                      <FormRevisionHistory
                        onClose={() => onClose()}
                        formId={formJSON.id}
                        workspaceId={currentWorkspace.id}
                        onRevision={(newForm) => {
                          dispatch({
                            type: "UPDATED_FORM",
                            payload: newForm
                          });
                          onClose();
                        }}
                      />
                    )}
                  </VerticalSidenav.NavContent>
                </VerticalSidenav>
              )}
            </FormContext.Consumer>
          </div>
        </SinapiDragAndDropProvider>
      </FormProvider>
      {confirmationModal ? (
        <ManagementModal
          modalSize="md"
          classNames={{
            modal: "modal-dialog modal-success modal-dialog-centered",
            body: "bg-gradient-success",
            footer: "bg-gradient-success"
          }}
          buttons={[
            {
              label: "Not Yet",
              icon: "fas fa-times mr-2",
              color: "link",
              size: "md",
              onClick: () => setConfirmationModal(null),
              className: "mr-auto text-white"
            },
            {
              label: "Yes, Publish Form",
              icon: "fab fa-telegram-plane mr-2",
              color: "secondary",
              size: "md",
              onClick: () => {
                publishFormAction(
                  confirmationModal.formId,
                  confirmationModal.allFields,
                  confirmationModal.allFieldsWithCost
                );
              }
            }
          ]}
        >
          <Row>
            <Col className="text-center">
              <i className="fab fa-telegram-plane fa-6x" />
              <h1 className="mt-2 text-white">Confirm Your Publish!</h1>
              <hr className="my-2" style={{ backgroundColor: "white" }} />
              <h3 className="mx-4 text-white font-weight-light">
                {confirmationModal.type === "republish"
                  ? "All changes you have made will be reflected LIVE on your website."
                  : "Your Form will be live and ready to be added to your website."}
              </h3>
            </Col>
          </Row>
        </ManagementModal>
      ) : null}
      {publishFormModal ? (
        <ManagementModal
          modalSize="lg"
          classNames={{
            modal: "modal-dialog modal-dialog-centered",
            header: "text-white",
            footer: "bg-gradient-light py-3"
          }}
          customClose={
            <Button
              style={{ background: "transparent", borderColor: "transparent", color: "white" }}
              onClick={() => setPublishFormModal(null)}
            >
              <i className="fas fa-times fa-2x"></i>
            </Button>
          }
          styles={{ modal: { maxWidth: "850px" }, header: { backgroundColor: "#172b4d" } }}
          header={
            publishFormModal.type === "publish" ? (
              <div>
                <h1 className="ml-3 mb-0 display-2 text-white">Congrats!</h1>
                <h4 className="ml-3 mb-1 text-white font-weight-light">
                  Your Form is now live. Anyone visiting your form will see the latest changes..
                </h4>
              </div>
            ) : (
              <div>
                <h2 className="ml-3 mb-0 display-3 text-white">Ready To Publish?</h2>
                <h4 className="ml-3 mb-1 text-white font-weight-light">How would you like to add it to your website</h4>
              </div>
            )
          }
          buttons={
            publishFormModal.type === "publish"
              ? [{ label: "Finished", color: "secondary", size: "md", onClick: () => setPublishFormModal(null) }]
              : [
                  {
                    label: "Close",
                    color: "secondary",
                    size: "md",
                    onClick: () => setPublishFormModal(null),
                    className: "mr-auto"
                  },
                  {
                    label: "Publish Updates",
                    color: "primary",
                    size: "md",
                    onClick: () => {
                      setPublishFormModal(null);
                      setConfirmationModal({
                        type: "republish",
                        formId,
                        allFields: publishFormModal.allFields,
                        allFieldsWithCost: publishFormModal.allFieldsWithCost
                      });
                    }
                  }
                ]
          }
        >
          <PublishCodeContainer
            formId={publishFormModal.formId}
            buttonTo={buttonTo}
            setButtonTo={setButtonTo}
            workspaceId={publishFormModal.workspaceId}
            company={currentUser.company}
            guid={publishFormModal.guid}
            userId={publishFormModal.userId}
            actionButtonSchema={actionButtonSchema}
            floatingButtonSchema={floatingButtonSchema}
            onEditStyles={() => selectEditOption("formThemes")}
            onActiveTab={() => dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "2" })}
            onClose={() => setPublishFormModal(null)}
          />
        </ManagementModal>
      ) : null}
    </>
  );
}

export default FormBuilder;
